// Navbar animation on hover
$(function () {
  const isSingle = window.$('.single.single-post');

  if (isSingle.length > 0) {
    const monarchSocial = window.$('#footer .et_social_inline');
    const monarchTopLayout = window.$('#md-top-layout .et_social_inline');

    monarchSocial.remove();
    monarchTopLayout.remove();
  }
});
